<template>
  <v-card>
    <v-card-title class="text-h6"
      >{{ item.sku }} - {{ item.customer_goods_barcode }}</v-card-title
    >
    <v-card-text>Cập nhật giá, phí, ghi chú, sub-barcode</v-card-text>

    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model.number="itemProperty.cost_custom_import"
            class="c-input-xs mb-3"
            type="number"
            label="Nhập"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.number="itemProperty.cost_custom_store"
            class="c-input-xs mb-3"
            type="number"
            label="Lưu"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.number="itemProperty.cost_custom_export"
            class="c-input-xs mb-3"
            type="number"
            label="Xuất"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <!--
        <v-col cols="4">
          <v-text-field v-model.number="itemProperty.wholesale_price" class="c-input-xs mb-3" type="number"
                        label="Giá sỉ" dense outlined clearable hide-details></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model.number="itemProperty.price" class="c-input-xs mb-3" type="number"
                        label="Giá lẻ" dense outlined clearable hide-details></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model.number="itemProperty.insurance_value" class="c-input-xs mb-3" type="number"
                        label="B.Hiểm" dense outlined clearable hide-details></v-text-field>
        </v-col>
        -->
        <v-col cols="4">
          <v-text-field
            v-model="itemProperty.sub_barcode"
            class="c-input-xs mb-3"
            type="text"
            label="Sub-Code"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-textarea
            v-model="itemProperty.comment"
            class="c-input-xs"
            rows="2"
            label="Ghi chú"
            dense
            outlined
            hide-details
          ></v-textarea>
        </v-col>
        <v-col cols="4">
          <v-checkbox
            v-model.number="itemProperty.uid_printed_on_item"
            class="c-input-xs mb-3"
            type="number"
            label="UID"
            dense
            outlined
            clearable
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel"> Hủy </v-btn>
      <v-btn color="green darken-1" text @click="confirm"> Xác nhận </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "UpdateCost",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    itemProperty: {},
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        httpClient.post("/goods/v1/update-cost", {
          id: this.item.id,
          ...this.itemProperty,
        });
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
  },
  mounted() {
    this.itemProperty = { ...this.item };
  },
};
</script>

<style scoped></style>
